import { graphql } from "gatsby";
import React from "react";
import List from "@src/components/post/list";
import Footer from "@src/components/footer";

import { Header } from "@src/components/header";
import { TagWithoutLink } from "@src/components/post/tag";

import { Theme } from "@src/theme";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;

  return (
    <Theme>
      <Header small>
        <TagWithoutLink name={tag} />
      </Header>
      <List data={data} />
      <Footer />
    </Theme>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          ...PostCardFragment
        }
      }
    }
  }
`;
